import { AppState } from 'redux/store';
import { StateController } from 'state-controller';
import { ProductionStatusEnum } from 'types/status-enums';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { LocationTheProductionStatusIsChangingFrom } from 'types/common-enums';
import { ProductionListActions } from 'pages/production/controllers/production-list-controller/production-list.controller';
import { GroupByEnum } from 'services/production-workflow.model';

export type NestedWorkflowModel = {
  id: string;
  name: string;
  status: ProductionStatusEnum;
};

export type StopProductionModalState = {
  isOpen: boolean;
  workflowId: string;
  isFetching: boolean;
  selectedIds: string[];
  isProcessing: boolean;
  productionTitle: string;
  nestedWorkflows: NestedWorkflowModel[];
  location: LocationTheProductionStatusIsChangingFrom;
};

const defaultState: StopProductionModalState = {
  isOpen: false,
  workflowId: '',
  selectedIds: [],
  isFetching: false,
  isProcessing: false,
  nestedWorkflows: [],
  productionTitle: '',
  location: LocationTheProductionStatusIsChangingFrom.ProductionList,
};

const stateController = new StateController<StopProductionModalState>('STOP_PRODUCTION_MODAL', defaultState);

export class StopProductionActions {
  public static openModal(id: string, name: string, location: LocationTheProductionStatusIsChangingFrom) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            location,
            isOpen: true,
            workflowId: id,
            isFetching: true,
            productionTitle: name,
          })),
        );

        const data = (await ProductionWorkflowService.getInfoForStopping(id)).components?.filter(
          (w) => w.status === ProductionStatusEnum.In_Progress,
        );
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isFetching: false,
            nestedWorkflows: data,
            selectedIds: data.map((i) => i.id),
          })),
        );
      } catch {
        dispatch(stateController.setState({ ...defaultState }));
      }
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
      setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);
    };
  }

  public static stopProduction() {
    return async (dispatch, getState: () => AppState) => {
      const { workflowId, selectedIds, location } = getState().production.stopProductionModal;
      const { groupBy } = getState().production.filters;

      try {
        dispatch(stateController.setState({ isProcessing: true }));
        await ProductionWorkflowService.stopProductionWorkflow(workflowId, selectedIds);

        if (location === LocationTheProductionStatusIsChangingFrom.ProductionList) {
          if (groupBy !== GroupByEnum.None) {
            dispatch(ProductionListActions.closeAllProductionsAndProductionGroups());
            dispatch(ProductionListActions.resetProductionsByIds());
          }

          await dispatch(
            ProductionFiltersActions.getProductionsByFilter({ showFetchEffect: false, resetSkipPreserveTake: true }),
          );
        } else {
          const { id } = getState().production_workflow.productionInfo;
          await dispatch(ProductionWorkflowActions.silentLoad({ id, disableAdditionalTasksSet: true }));
        }

        dispatch(stateController.setState({ isProcessing: false }));
      } finally {
        dispatch(StopProductionActions.closeModal());
      }
    };
  }

  public static onSelectChange(id: string) {
    return async (dispatch, getState: () => AppState) => {
      const { selectedIds } = getState().production.stopProductionModal;
      if (selectedIds.includes(id)) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            selectedIds: prev.selectedIds.filter((i) => i !== id),
          })),
        );
      } else {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            selectedIds: [...prev.selectedIds, id],
          })),
        );
      }
    };
  }
}

export const reducer = stateController.getReducer();
